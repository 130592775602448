//Angular
import { NgModule, ApplicationRef, NO_ERRORS_SCHEMA, ErrorHandler, Injector, APP_INITIALIZER, NgZone } from '@angular/core';
import { AppComponent } from '~/app';
import { Router, Scroll } from '@angular/router';
import { CommonModule, ViewportScroller } from '@angular/common';

import { appRouting } from '~/routing/appRouting';
import { removeNgStyles, createNewHosts } from '@angularclass/hmr';

//Modules
import { SharedModule } from '~/modules/sharedModule';
import { DOCUMENT } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Services
import { TitleTemplateService } from '~/templates/services/titleService';
import { SocketService } from '~/services/api/web/socket/socketService';

//Angulartics2
import { Angulartics2Module } from 'angulartics2';

// RxJS
import { filter } from 'rxjs/operators';

//HCaptcha
import { NgHcaptchaModule } from 'ng-hcaptcha';

// Okta - https://github.com/okta/okta-angular
import { OktaAuthModule, OktaAuthConfigService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { StorageType } from '@okta/okta-auth-js/types/lib/storage';

// Initialise okta config and provide via APP_INITIALIZER so we can access runtime services during various callbacks
function configInitializer(oktaAuthConfigService: OktaAuthConfigService, router: Router, zone: NgZone): () => void {
    return () => {
        var authConfig =  {
            issuer: process.env['OKTA_AUTH_SERVER'] ,
            clientId: process.env['OKTA_CLIENT_ID'],
            redirectUri: process.env['OKTA_REDIRECT_URI'] ,
            logoutUrl: process.env['OKTA_LOGOUT_URL'] ,
            postLogoutRedirectUri: process.env['OKTA_LOGOUT_URL'],
            scopes: ['openid', 'profile', 'email', 'offline_access'],
            storageManager:{
                transaction:{
                    storageType: 'localStorage' as StorageType
                },
            },
            // Here we want access to NgZone and Router so we can safely navigate while adhering to Angular's zone rules
            restoreOriginalUri: async (_oktaAuth, _originalUri) => {
                await zone.run(() => router.navigate(['/dashboard']));
            }
        };
        
        var oktaAuth = new OktaAuth(authConfig);
        oktaAuthConfigService.setConfig({ 
            oktaAuth,
            // Here we want access to NgZone and Router so we can safely navigate while adhering to Angular's zone rules
            onAuthRequired: async (_oktaAuth: OktaAuth, _injector: Injector) => {
                await zone.run(() => router.navigate(['/login']));
            } 
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        SharedModule,

        Angulartics2Module.forRoot(),
        appRouting,
        OktaAuthModule.forRoot(),
        NgHcaptchaModule.forRoot({
            siteKey: process.env['HCAPTCHA_SITE_KEY'],
            languageCode: 'en'
        }),
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        TitleTemplateService,
        SocketService,
        {          
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            deps: [OktaAuthConfigService, Router, NgZone],
            multi: true
        },
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    exports: [
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
    ]
})

export class AppModule {
    public appRef:ApplicationRef;
    private router:Router;
    private viewportScroller:ViewportScroller;

    constructor(appRef: ApplicationRef, router: Router, viewportScroller: ViewportScroller) {
        this.appRef = appRef;
        this.router = router;
        this.viewportScroller = viewportScroller;

        router.events.pipe(
            filter((event: any) => {
                return event instanceof Scroll;
            })
        ).subscribe((event) => {
            if(event.anchor) {
                let element:HTMLElement = document.getElementById(event.anchor);
                if(element) {
                    element.scrollIntoView();
                }
            }
            else {
                let adminRegex:RegExp = new RegExp('^/admin');
                if(adminRegex.test(router.url)) {
                    let pageTplMain:HTMLElement = document.getElementById('page-tpl__main');
                    pageTplMain.scrollTop = 0;
                }
                else {
                    let loginOpacity:HTMLElement = document.getElementById('tpl--login__opacity');
                    if(loginOpacity) {
                        loginOpacity.scrollTop = 0;
                    }
                }
            }
        });
    }

    public hmrOnInit(store) {
        console.log('HMR store', store);
    }

    public hmrOnDestroy(store) {
        let cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // remove styles
        removeNgStyles();
    }

    public hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        delete store.disposeOldHosts;
    }
}

declare global{
    interface Navigator{
       msSaveBlob:(blob: Blob,fileName:string) => boolean
       msSaveOrOpenBlob:(blob: Blob,fileName:string) => boolean
       }
    }
