import { Injectable } from '@angular/core';
import { InventoryAbstractService } from '~/services/api/web/inventory/inventoryAbstractService';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { BreakDownModel, MobBreakDownModel } from '~/models/breakDownModel';
import { InventoryBaleModel } from '~/models/inventoryBaleModel';
import { EntityModel } from '~/models/entityModel';
import { BrandModel } from '~/models/brandModel';
import { InsightModel } from '~/models/insightModel';
import { MobRelatedItemsModel } from '~/models/mobRelatedItemsModel';
import { BaleRelatedItemsModel } from '~/models/baleRelatedItemsModel';
import { LineModel } from '~/models/lineModel';
import { map as _map } from 'lodash';
import { InventoryLotModel } from '~/models/inventoryLotModel';
import { InventoryLineModel } from '~/models/inventoryLineModel';
import { InventoryMobModel } from '~/models/inventoryMobModel';
import { InventorySpecieModel } from '~/models/inventorySpecieModel';
import { InventoryClipModel } from '~/models/inventoryClipModel';
import { InventoryOrderModel } from '~/models/inventoryOrderModel';
import { LineTestResultModel } from '~/models/lineTestResultModel';
import { ReadyReckonerModel } from '~/models/readyReckonerModel';
import { ServerFilterModel } from '~/models/serverPagination/serverFilterModel';
import { ServerPaginationModel } from '~/models/serverPagination/serverPaginationModel';
import { LotSummaryModel } from '~/models/lotSummaryModel';
import { OrderSummaryModel } from '~/models/orderSummaryModel';

@Injectable()
export class InventoryService extends BaseWebService implements InventoryAbstractService {

    public static readonly PATH: string = '/inventory';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getMobs(entity?: EntityModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs';
        let params = new HttpParams();
        
        if (entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getMobById(mobId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs/' + mobId.toString();

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryMobModel(response['data']);
        });
    }

    public getMobRelatedItems(mobId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs/' + mobId.toString() + '/related-items';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {

            let bales: InventoryBaleModel[] = [];
            let species: InventorySpecieModel[] = [];
            let clip: InventoryClipModel = null;

            if (response['data']) {
                if (response['data']['bales']) {
                    bales = _map(response['data']['bales'], (bale) => new InventoryBaleModel(bale));
                }
                if (response['data']['species']) {
                    species = _map(response['data']['species'], (specie) => new InventorySpecieModel(specie));
                }
                if (response['data']['clip']) {
                    clip = new InventoryClipModel(response['data']['clip']);
                }
            }

            let data: MobRelatedItemsModel = new MobRelatedItemsModel({
                bales: bales,
                species: species,
                clip: clip
            });

            return data;
        });
    }

    public getMobInsight(mobId:number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs/' + mobId.toString() + '/insight';

        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new InsightModel(response['data']);
        });
    }

    public getMobByYear(mobId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs/' + mobId.toString() + '/byyear';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new MobBreakDownModel(inventoryMob));
        });
    }

    public getWooltypeByMob(mobId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/mobs/' + mobId.toString() + '/balesbywooltype';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new MobBreakDownModel(inventoryMob));
        });
    }

    public getBales(entity?: EntityModel, brand?: BrandModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/bales';

        let params = new HttpParams();

        if (entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        if (brand.brandId) {
            params = params.append('brandId', brand.brandId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }

    public getBaleById(baleId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/bales/' + baleId.toString();

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryBaleModel(response['data']);
        });
    }

    public getBaleRelatedItems(baleId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/bales/' + baleId.toString() + '/related-items';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {

            let mobs: InventoryMobModel[] = [];
            let species: InventorySpecieModel[] = [];
            let clip: InventoryClipModel = null;
            let line: InventoryLineModel = null;
            let lot: InventoryLotModel = null;
            let order: InventoryOrderModel = null;


            if (response['data']) {
                if (response['data']['mobs']) {
                    mobs = _map(response['data']['mobs'], (mob) => new InventoryMobModel(mob));
                }
                if (response['data']['species']) {
                    species = _map(response['data']['species'], (specie) => new InventorySpecieModel(specie));
                }
                if (response['data']['clip']) {
                    clip = new InventoryClipModel(response['data']['clip']);
                }
                if (response['data']['lot']) {
                    lot = new InventoryLotModel(response['data']['lot']);
                }
                if (response['data']['order']) {
                    order = new InventoryOrderModel(response['data']['order']);
                }
                if (response['data']['line']) {
                    line = new InventoryLineModel(response['data']['line']);
                }

            }

            let data: BaleRelatedItemsModel = new BaleRelatedItemsModel({
                mobs: mobs,
                species: species,
                clip: clip,
                line: line,
                lot: lot,
                order: order
            });

            return data;
        });
    }

    public getBaleAverage(baleId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/bales/' + baleId.toString() + '/average';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new BreakDownModel(response['data']);
        });
    }

    public getLineTestResults(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/linetestresult';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (model:{}) => new LineTestResultModel(model));
        });
    }



    public getMobBreakDownByBale(baleId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/bales/' + baleId.toString() + '/mob-breakdown';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (model:{}) => new MobBreakDownModel(model));
        });
    }

    public getLots(entity?: EntityModel, brand?: BrandModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots';

        let params = new HttpParams();
        if (entity?.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        if (brand?.brandId) {
            params = params.append('brandId', brand.brandId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (lot) => new InventoryLotModel(lot));
        });
    }

    public searchLots(searchModel?: ServerPaginationModel<LotSummaryModel>): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/search';
        let body:any = searchModel;
        let options:{} = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          };

        return this.http.post(url, body, options, (response: HttpResponse<any>) => {
            return new ServerPaginationModel<LotSummaryModel>(LotSummaryModel, response['data']);
        });
    }

    public getLotById(lotId: number, entityId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + '/traceability/' + entityId + '/lot/' + lotId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryLotModel(response['data']);
        });
    }

    public searchOrders(searchModel?: ServerPaginationModel<OrderSummaryModel>): Observable<ResponseModel> {
        let url:string = InventoryService.URL + '/order/search';
        let body:any = searchModel;
        let options:{} = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          };
          
        return this.http.post(url, body, options, (response: HttpResponse<any>) => {
            return new ServerPaginationModel<OrderSummaryModel>(OrderSummaryModel, response['data']);
        });
    }
    
    public saveLotTestResult(lot: InventoryLotModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/testresult/'+lot.id;
        let body:any = lot;
        let options:{} = {};

        return this.http.post(url, body, options, (response: HttpResponse<any>) => {
            return new InventoryLotModel(response['data']);
        });
    }


    public getLotRelatedBaleItems(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/bales';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }

    public getLotRelatedMobItems(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/mobs';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getLotRelatedSpecieItems(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/species';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (specie) => new InventorySpecieModel(specie));
        });
    }

    public getMobBreakDownByLot(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/mob-breakdown';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (specie) => new MobBreakDownModel(specie));
        });
    }

    public getLotAverage(lotId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lots/' + lotId.toString() + '/average';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new BreakDownModel(response['data']);
        });
    }

    public getLines(entity?: EntityModel, brand?: BrandModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines';

        let params = new HttpParams();
        if (entity && entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        if (brand && brand.brandId) {
            params = params.append('brandId', brand.brandId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (line) => new LineModel(line));
        });
    }

    public getLineById(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString();

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new LineModel(response['data']);
        });
    }

    public getLineRelatedBaleItems(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/bales';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }

    public getLineRelatedMobItems(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/mobs';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getLineRelatedSpecieItems(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/specie';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (specie) => new InventorySpecieModel(specie));
        });
    }

    public getLineRelatedClipItems(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/clip';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryClipModel(response['data']);
        });
    }

    public getLineAverage(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/average';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new BreakDownModel(response['data']);
        });
    }

    public getMobBreakDownByLine(lineId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/lines/' + lineId.toString() + '/mob-breakdown';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (model:{}) => new MobBreakDownModel(model));
        });
    }

    public getSpecies(entity?: EntityModel, brand?: BrandModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species';

        let params = new HttpParams();

        if (entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        if (brand.brandId) {
            params = params.append('brandId', brand.brandId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (specie) => new InventorySpecieModel(specie));
        });
    }

    public getSpecieById(specieId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + specieId.toString();

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventorySpecieModel(response['data']);
        });
    }

    public getSpecieRelatedMobItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + clipId.toString() + '/mobs';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getSpecieRelatedBaleItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + clipId.toString() + '/bales';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }
    public getSpecieRelatedLotItems(specieId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + specieId.toString() + '/lots';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (lot) => new InventoryLotModel(lot));
        });
    }

    public getSpecieRelatedLineItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + clipId.toString() + '/lines';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (line) => new LineModel(line));
        });
    }

    public getSpecieRelatedClipItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + clipId.toString() + '/clip';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryClipModel(response['data']);
        });
    }

    public getSpecieAverage(specieId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + specieId.toString() + '/average';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new BreakDownModel(response['data']);
        });
    }

    public getMobBreakDownBySpecie(specieId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + specieId.toString() + '/mob-breakdown';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new MobBreakDownModel(response['data']);
        });
    }

    public getProductionByWoolType(specieId:number):Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/species/' + specieId.toString() + '/production-by-wooltype';

        let options:{} = {};
        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (item:{}) => new BreakDownModel(item));
        });
    }

    public getClips(entity?: EntityModel): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips';

        let params = new HttpParams();

        if (entity && entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (clip) => new InventoryClipModel(clip));
        });
    }

    public getClipById(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString();

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryClipModel(response['data']);
        });
    }

    public getClipRelatedMobItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/mobs';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getClipRelatedBaleItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/bales';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }

    public getClipRelatedLineAndLotItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/linesandlots';

        let options:{} = {};


        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (line) => new InventoryLotModel(line));
        });
    }

    public getClipRelatedLineItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/lines';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (line) => new InventoryLineModel(line));
        });
    }

    public getClipRelatedSpecieItems(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/species';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (specie) => new InventorySpecieModel(specie));
        });
    }

    public getClipByYear(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/byyear';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    public getClipInsight(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/insight';

        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new InsightModel(response['data']);
        });
    }

    public getWooltypeByClip(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/balesbywooltype';
        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    public getBaleBreakDownByClips(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/balesbyclip';
        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    public getMobBreakDownByClips(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/mob-breakdown';

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new MobBreakDownModel(inventoryMob));
        });
    }

    public getBalesByMicron(clipId: number): Observable<ResponseModel> {
        let url:string = InventoryService.URL + InventoryService.PATH + '/clips/' + clipId.toString() + '/balesbymicron';
        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }
}
