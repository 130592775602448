import { BaseModel } from '~/models/baseModel';
import { UserRoleMapper } from '~/decorators/userRoleMapper/userRoleMapperDecorator';
import { ArrayBooleanMapper } from '~/decorators/arrayBooleanMapper/arrayBooleanMapperDecorator';
import { isEmpty as _isEmpty } from 'lodash';

export class UserModel extends BaseModel {
    public contactId:number;
    public contactID:number; //@TODO remove this value when it is updated for /entity.users
    public contactTitle:string;
    public firstName:string;
    public lastName:string;
    public fullName:string;
    public email:string;
    // api is returning contactEmail instead of email
    // @see app/admin/pages/brand/views/brand/forms/usersForm/usersForm.html
    public contactEmail:string;
    public mobile:string;
    public profilePictureURL:string;
    public isKeyContact:boolean;

    @ArrayBooleanMapper(
        'contactTypesObject',
        [
            { key: 'isWoolGrower', value: 'WoolGrower' },
            { key: 'isClasser', value: 'Classer' },
            { key: 'isSeller', value: 'Seller' },
            { key: 'isBuyer', value: 'Buyer' },
            { key: 'isContractor', value: 'Contractor' },
            { key: 'isFarmManager', value: 'FarmManager' },
            { key: 'isWoolHandler', value: 'WoolHandler' }
        ]
    )
    public contactTypes:string[];
    public entityName:string;
    public isVerified:boolean;
    public edi:string;
    public entityNotification:string;
    public privateMessages:string;
    public eSpeciNotification:string;
    public woolInventoryNotification:string;
    public subscribeMarketing:boolean;
    public isNew:boolean;
    public isRegistered:boolean;
    public canTrade:boolean;
    public isMasterAccount:boolean;

    @UserRoleMapper([
        { key: 'profileAdminister', value: 'Administer' },
        { key: 'profileEdit', value: 'Edit' },
        { key: 'profileView', value: 'View' },
        { key: 'profileNone', value: 'None' },
    ])
    public role:string;

    @UserRoleMapper([
        { key: 'brandAdminister', value: 'Administer' },
        { key: 'brandEdit', value: 'Edit' },
        { key: 'brandView', value: 'View' }
    ])
    public brandRole:string;

    @UserRoleMapper([
        { key: 'clipAdminister', value: 'Administer' },
        { key: 'clipEdit', value: 'Edit' },
        { key: 'clipView', value: 'View' }
    ])
    public clipRole:string;

    public inventoryView:boolean;
    public resultsView:boolean;

    public keyContact:boolean;
    public displayUserProfile:boolean;

    public jobTitle:string;
    public region:string;

    public selectedTradingEntityId:number;

    constructor(values: Object = {}) {
        super(values);
    }

    public hasLabel() : boolean {
        return (!_isEmpty(this.contactTitle)) || (!_isEmpty(this.firstName)) || (!_isEmpty(this.lastName));
    }

    public getLabel() : string {
        let values:string[] = [];

        if(!_isEmpty(this.contactTitle)) {
            values.push(this.contactTitle);
        }
        if(!_isEmpty(this.fullName)) {
            values.push(this.fullName);
        }
        else {
            if(!_isEmpty(this.firstName)) {
                values.push(this.firstName);
            }
            if(!_isEmpty(this.lastName)) {
                values.push(this.lastName);
            }
        }

        if(values.length > 0 && values.join(' ').trim().length > 0) {
            //Join the array of the contactTitle, firstName and lastName separated by a space character.
            return values.join(' ').trim();
        }

        let email = (!_isEmpty(this.contactEmail)) ? this.contactEmail : '';

        if (_isEmpty(email) && !_isEmpty(this.email)) {
            email = this.email;
        }

        //Fallback to email or an empty string
        return email;
    }

    public getFullName() : string {

        if(!_isEmpty(this.fullName)) {
            return this.fullName;
        }

        let values:string[] = [];
        if(!_isEmpty(this.firstName)) {
            values.push(this.firstName);
        }
        if(!_isEmpty(this.lastName)) {
            values.push(this.lastName);
        }

        //Join the array of the firstName and lastName separated by a space character.
        return values.join(' ').trim();
    }
}
