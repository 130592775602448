import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { PartnerAbstractService } from '~/services/api/web/partner/partnerAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { PartnerModel } from '~/models/partnerModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { EditActAsGrowerModel } from '~/models/editActAsGrowerModel';
import { EntityModel, LiteEntityModel } from '~/models/entityModel';

@Injectable()
export class PartnerService extends BaseWebService implements PartnerAbstractService {

    public static readonly PATH:string = '/partners';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getSellersByEntityId(entityId:number) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/entities/' + entityId.toString() + '/sellers';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (partner) => new PartnerModel(partner));
        });
    }

    public getSellersByBrandId(brandId:number) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/brands/' + brandId.toString() + '/sellers';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (partner) => new PartnerModel(partner));
        });
    }

    public getActAsGrower(sellerId:number) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/actas/growers/' + sellerId.toString() ;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (partner) => new PartnerModel(partner));
        });
    }

    public getActAsSeller(growerId:number) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/actas/sellers/' + growerId.toString() ;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (partner) => new PartnerModel(partner));
        });
    }

    public saveActAsGrower(partner:EditActAsGrowerModel) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/actas/grower';
        let body = partner;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public saveEditBrand(partner:PartnerModel) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/actas/grower';
        let body = partner;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    /**
     * Adds a partnerStatus for each epiPartnerObject
     * @param {number} entityId
     * @param {[]} entities
     * @returns {Observable<>}
     */
    public addPartnerService(entityId:number, partners:LiteEntityModel[]) : Observable<ResponseModel> {
        let url:string = PartnerService.URL + PartnerService.PATH + '/entities/addpartnerstatus';
        let body = {
            entityId: entityId,
            entities: partners
        };
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            let entityId: number;
            let entities: LiteEntityModel[] = [];
            
            if (response['data']){
                if (response['data']['entityId']){
                    entityId = response['data']['entityId'];
                }
                if (response['data']['entities']){
                    entities = _map(response['data']['entities'], (epi) => new LiteEntityModel(epi))
                }
            }

            return {
                entityId: entityId,
                entities: entities
            };
        });
    }
}
