import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ImportAbstractService } from '~/services/api/web/import/importAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel } from '~/models/responseModel';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ImportModel } from '~/models/importModel';
import { ImportBaleModel } from '~/models/importBaleModel';
import { ImportLiteModel } from '~/models/importLiteModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';

@Injectable()
export class ImportService extends BaseWebService implements ImportAbstractService {

    public static readonly PATH:string = '/import';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getClientImportTemplate() : Observable<ResponseModel> {
        let url:string = ImportService.URL + ImportService.PATH + '/generateclientimport';

        let options:{} = { responseType: 'blob' };
        return this.http.get(url, options);
    }

    public postClientImport(file:any, shareTestDataWithGrower:boolean, shareOrderDataWithGrower: boolean, shareBrandDataWithBuyer: boolean, trialImport: boolean) : Observable<ResponseModel> {
        let url:string = `${ImportService.URL}${ImportService.PATH}/clientimport?shareTestDataWithGrower=${shareTestDataWithGrower}&shareOrderDataWithGrower=${shareOrderDataWithGrower}&shareBrandDataWithBuyer=${shareBrandDataWithBuyer}&trialImport=${trialImport}`;
        
        let options:{} = { responseType: 'blob' };
        return this.http.post(url, file, options);
    }


    public getEWoolItems() : Observable<ResponseModel> {
        let url:string = ImportService.URL + ImportService.PATH + '/ewool';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (importLite) => new ImportLiteModel(importLite));
        });
    }

    public getEWoolById(id:number) : Observable<ResponseModel> {
        let url:string = ImportService.URL + ImportService.PATH + '/ewool/' + id.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ImportModel(response['data']);
        });
    }

    public getUnassignedBales(clipId:number) : Observable<ResponseModel> {
        let url:string = ImportService.URL + ImportService.PATH + '/unassignedbales/' + clipId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (importBale) => new ImportBaleModel(importBale));
        });
    }

    public postEWool(id:number, importModel:ImportModel) : Observable<ResponseModel> {
        let url:string = ImportService.URL + ImportService.PATH + '/ewool/' + id.toString();
        let body:any = importModel;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}